import React from "react";
import { DialogTitle, DialogContent, Typography, Box } from "@mui/material";
import { Field } from "formik";
import { CornerCloseButton } from "../../../../components/Buttons";
import FormikField from "../../../../components/FormFields/FormikFields";
import { PhoneInput } from "../../../../utility/MaskComponent";

type AdminAddProfProps = {
  onClose: (success?: boolean) => void;
  countries: { name: string }[];
  professionalTypes: { id: number; name: string }[];
  professionalsList: { id: number; first_name: string; last_name: string }[];
};

const AdminAddProfContent: React.FC<AdminAddProfProps> = ({ onClose, countries, professionalTypes, professionalsList }) => {
  return (
    <>
      <DialogTitle sx={{ pt: 5 }}>
        Add Professional
        <CornerCloseButton onClick={() => onClose(false)} />
      </DialogTitle>
      <DialogContent>
        <Field name="firstName" label="First Name" component={FormikField} fullWidth margin="dense" />
        <Field name="lastName" label="Last Name" component={FormikField} fullWidth margin="dense" />
        <Field name="email" label="Email" component={FormikField} fullWidth margin="dense" />
        <Field name="phone" label="Mobile Phone" component={PhoneInput} fullWidth margin="dense" />
        <Field
          name="country"
          label="Country / Region"
          component={FormikField}
          componentType="select"
          options={countries.map((country) => ({
            label: country.name,
            value: country.name,
          }))}
          fullWidth
          margin="dense"
        />
        <Field
          name="typeId"
          label="Type of Professional"
          component={FormikField}
          componentType="select"
          options={professionalTypes.map((type) => ({
            label: type.name,
            value: type.id,
          }))}
          fullWidth
          margin="dense"
        />
        <Box>
          <Typography variant="inherit" gutterBottom sx={{ fontWeight: "bold", mt: 2 }}>
            Access to Clients:
          </Typography>
          <Typography variant="body2" gutterBottom sx={{ fontStyle: "italic" }}>
            Using the textbox below, enter the name of the professional(s) that the new user should have access to their
            clients in AdvisorVision.
          </Typography>
          <Field
            name="linkedProfessionals"
            label="Professional"
            component={FormikField}
            componentType="autocomplete"
            options={
              professionalsList.map((pro) => ({
                label: `${pro.first_name} ${pro.last_name}`,
                value: pro.id,
              }))}
            multiple={true}
          />
          <Field
            name="receivesEmailNotices"
            label="Receives email notices for associated clients"
            component={FormikField}
            componentType="switch"
            customProps={{ color: "success" }}
            sx={{ mt: 2 }}>

          </Field>
          <Typography variant="subtitle2" gutterBottom sx={{ fontStyle: "italic", mt: 2 }}>
            New User will be granted access to the same households as the list above.
          </Typography>
        </Box>
      </DialogContent>
    </>
  );
};

export default AdminAddProfContent;
