import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography, } from "@mui/material";
import { FC, useState, useMemo, useEffect } from "react";
import { WarningAmber, ErrorOutline, CheckCircleOutline } from "@mui/icons-material";
import { CornerCloseButton } from "../Buttons";

type ReassignOption = { label: string; value: string };

type ResourceToReassign = {
  identifierName: string;
  identifierLabel: string;
  reassignOptions: ReassignOption[];
  labelText: string;
  subLabelText?: string;
  emptyLabelText?: string;
};

type ResourceReassignmentConfirmationDialogProps = Omit<DialogProps, "onClose"> & {
  handleClose: (confirmed: boolean, reassignValue?: string) => void;
  resourceToReassign: ResourceToReassign;
  dialogTitle?: string;
  headingLabel?: string;
  promptText?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
};

export const ResourceReassignmentConfirmationDialog: FC<ResourceReassignmentConfirmationDialogProps> = ({
  open,
  handleClose,
  resourceToReassign,
  headingLabel,
  promptText,
  dialogTitle,
  confirmButtonLabel,
  cancelButtonLabel,
  ...rest
}) => {
  const [selectedReassignValue, setSelectedReassignValue] = useState<string | undefined>();

  const {
    labelText,
    subLabelText,
    emptyLabelText = "No options available to reassign.",
    reassignOptions,
    identifierName,
    identifierLabel,
  } = resourceToReassign;

  const titleText = dialogTitle ?? "Reassign before Confirming";
  const confirmText = confirmButtonLabel ?? "Confirm";
  const cancelText = cancelButtonLabel ?? "Cancel";
  const headingText = headingLabel ?? `You are about to delete ${identifierName}`;
  const finalPrompt = promptText ?? "Do you want to proceed?";

  const memoizedReassignOptions = useMemo(() =>
    reassignOptions.map((option) => (
      <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
    )),
  [reassignOptions]);

  useEffect(() => {
    if (!open) {
      setSelectedReassignValue(undefined);
    }
  }, [open]);

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedReassignValue(event.target.value as string);
  };

  const onConfirm = () => {
    handleClose(true, selectedReassignValue);
  };

  const onCancel = () => {
    handleClose(false);
  };

  const isConfirmDisabled = reassignOptions.length > 0 && !selectedReassignValue;

  return (
    <Dialog open={open} {...rest} fullWidth maxWidth="sm">
      <DialogTitle sx={{ position: "relative", pl: 2, pb: 1 }}>
        <Box display="flex" alignItems="center">
          <WarningAmber color="error" sx={{ mr: 1 }} />
          <Typography variant="body1" sx={{ textAlign: "left", fontWeight: "bold" }}>
            {titleText}
          </Typography>
        </Box>
        <CornerCloseButton onClick={onCancel} />
      </DialogTitle>

      <DialogContent>
        <Box textAlign="center">
          <ErrorOutline color="error" sx={{ fontSize: 40, mb: 1 }} />
          <Typography variant="h6" sx={{ color: "error.main" }}>
            {headingText}
          </Typography>
        </Box>
        <DialogContentText variant="body2" sx={{ mb: 1, textAlign: "center" }}>
          {labelText}
        </DialogContentText>
        {subLabelText && (
          <DialogContentText variant="subtitle2" sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}>
            {subLabelText}
          </DialogContentText>
        )}

        {reassignOptions.length > 0 ? (
          <FormControl fullWidth>
            <InputLabel id="reassign-select-label">Select New {identifierLabel}</InputLabel>
            <Select
              labelId="reassign-select-label"
              value={selectedReassignValue ?? ""}
              label={`Select New ${identifierLabel}`}
              onChange={handleSelectChange}
            >
              {memoizedReassignOptions}
            </Select>
          
          </FormControl>
        ) : (
          <Box textAlign="center">
            <DialogContentText variant="body2" sx={{ mt: 1, color: "text.secondary" }}>
              {emptyLabelText}
            </DialogContentText>
          </Box>
        )}
      </DialogContent>

      <Typography variant="h6" sx={{ textAlign: "center", mt: 2, mb: 2 }}>
        {finalPrompt}
      </Typography>
      <DialogActions sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <Button onClick={onCancel} variant="outlined" size="large">
          {cancelText}
        </Button>
        <Button
          color="info"
          onClick={onConfirm}
          variant="contained"
          size="large"
          disabled={isConfirmDisabled}
          sx={{
            transition: "background-color 0.3s",
            "&:hover": {
              backgroundColor: "info.dark",
            },
          }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};