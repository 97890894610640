import moment from "moment";
import { array, boolean, date, mixed, number, object, string } from "yup";
import { SubDocQuestionnaireDto } from "../../../../api/payloads";
import { AchInstructionModel, BrokerageInstructionModel, CheckInstructionModel, WireInstructionModel } from "../../../../api/payloads/customer.payload";
import { DistributionInstructionType, RevocableStrings } from "../../../../utility/constants";
import { defaultCountryData, isNullish } from "../../../../utility/misc.util";
import { invalidFileDataTest, largeFileTest, largeFileTestMessage } from "../../../../utility/file.util";

type AuthorizedRepresentative = {
  representativeContactId: "" | number,
  representativeAddress: string,
  ssn: string,
  dob: moment.Moment | null,
}

type BeneficialOwner = {
  ownerName: string,
  ownerAddress: string,
  ssn: string,
  dob: moment.Moment | null,
  contactId?: number,
}

export type InvestorQuestionnaireFormValues = {
  entityAccount: boolean,
  mailingSameAsPermanentAddress: boolean,
  mailingAddress: {
    street1: string,
    street2?: string,
    stateId: number | "",
    city: string,
    zip: string
  },
  fundSubscriber?: boolean | 1 | 0 | null,
  authorizedRepresentatives: AuthorizedRepresentative[]
  beneficialOwners: BeneficialOwner[],
  optsOutBeneficialOwners: boolean,
  owners?: {email: string, ssn: string, date_of_birth: string, phone_number: {number: string}[]}[],
  ein?: string,
}

export type AccreditedInvestorFormValues = {
  accreditedAnswers: number[]
}

export type FinancialAdvisorFormValues = {
  firm: string | number,
  advisorId: "" | number,
  advisorEmail: string,
  advisoryAddress: {
    street1: string,
    street2?: string,
    city: string,
    stateId: "" | number,
    zip: string,
  }
  advisorPhoneNumber: string,
}

export type RepresentationOfResidenceFormValues = {
  residenceAccountOwner: string,
  residenceCountryId: string,
  residenceStateId: number | "",
}

export type ElectronicDeliveryFormValues = {
  isElectronicDeliveryConfirmed: 0 | 1 | null,
}

export type OwnerIdDocument = {
  contactId: number
  idImageFile: File | {name: string} | null,
  expirationDate: moment.Moment | null,
  name: string,
  imageId?: number | null,
}

export type EntityDocumentFormValue = {
  documentId: number | null,
  documentName: string,
}

export type SupportingDocumentFormValue = {
  supportingDocumentFile: File | {name: string} | null,
  documentId: number | null,
  documentName: string,
}

export type AMLVerificationFormValues = {
  formationDocuments: EntityDocumentFormValue | null,
  governingDocuments: EntityDocumentFormValue | null,
  trustDocuments: EntityDocumentFormValue | null,
  ownerIdDocuments: OwnerIdDocument[],
  supportingDocuments: SupportingDocumentFormValue[],
  sourceOfFunds: string,
  otherSource: string,
}

export type VerificationDocument = {
  documentId: number | null,
  documentName: string,
}

export type VoidedCheck = {
  documentId: number | null,
  documentName: string,
}

export type DistributionInformationFormValues = {
  distributionInstructionType: DistributionInstructionType,
  distributionInstructionId: number | "",
  wireInstructions: WireInstructionModel & { verificationDocument: VerificationDocument | null},
  brokerageInstructions: BrokerageInstructionModel & {verificationDocument: VerificationDocument | null},
  achInstructions: AchInstructionModel & {voidedCheck: VoidedCheck | null}
  checkInstructions: CheckInstructionModel
}

export type SupplementalDataFormValues = {
  dateOfIncorporation: moment.Moment | null,
  stateOfIncorporation: number | "",
  fundSpecificEntity: boolean | 0 | 1 | null,
  revocable: string,
  typeOfTrust: string,
  grantor: string,
  beneficiary: string,
  trustees: string,
}

export type PurchaseFundingInstructionFormValues = {
  isFunded: 0 | 1 | null,
  fundingDate: moment.Moment | null,
  fundingType: string,
  isOfficer: 0 | 1 | null,
  investorNatureOfRelationship: string,
  investorPercentOwned: number | "",
}

export type ReviewStepFormValues = {
  advisorAcknowledgement: boolean,
}

export type SubDocsFormValues = 
  InvestorQuestionnaireFormValues & 
  AccreditedInvestorFormValues &
  AMLVerificationFormValues &
  DistributionInformationFormValues &
  FinancialAdvisorFormValues &
  RepresentationOfResidenceFormValues &
  SupplementalDataFormValues &
  ElectronicDeliveryFormValues &
  PurchaseFundingInstructionFormValues

export const FinancialAdvisorFormSchema = object({
  firm: string().required("Firm is required"),
  advisorId: string().required("Financial advisor name is required"),
  advisoryAddress: object({
    street1: string().required("Financial advisor mailing address is required"),
    street2: string().optional(),
    city: string().required("City is required"),
    stateId: number().required("State is required"),
    zip: string().min(5, "Zip Code must be at least 5 digits").required("Required")
  }),
  advisorEmail: string().email("Invalid email").required("Email is required"),
  advisorPhoneNumber: string().required(),
})

export const RepresentationOfResidenceFormSchema = object({
  residenceAccountOwner: string().required("Owner is required"),
  residenceCountryId: string().required("Country is required"),
  residenceStateId: string().required("State is required"),
})

export const AMLVerificationFormSchema = object({
  formationDocuments: object({documentId: number(), documentName: string()}).nullable(),
  governingDocuments: object({documentId: number(), documentName: string()}).nullable(),
  trustDocument: object({documentId: number(), documentName: string()}).nullable(),
  ownerIdDocuments: array().of(object({
    idImageFile: mixed()
      .required('Required')
      .test('invalidFileData', "Invalid file data", invalidFileDataTest)
      .test('fileSize', largeFileTestMessage, largeFileTest), 
    imageId: number().nullable(),
    expirationDate: date().typeError("Must be a valid date").min(new Date(), "Please pick a date in the future").required(),
  })),
  supportingDocuments: array().of(object({
    supportingDocumentFile: mixed()
      .nullable()
      .test('invalidFileData', "Invalid file data", invalidFileDataTest)
      .test('fileSize', largeFileTestMessage, largeFileTest),
    documentId: number().nullable(),
    documentName: string().notRequired()
  })).nullable().when(['entityAccount','formationDocuments', 'governingDocuments','trustDocuments'], {
    is: (entityAccount: boolean, formationDocuments: EntityDocumentFormValue, governingDocuments: EntityDocumentFormValue, trustDocument: EntityDocumentFormValue) => {
      if (!entityAccount) return false

      if (formationDocuments && formationDocuments.documentId) return false

      if (governingDocuments && governingDocuments.documentId) return false

      if (trustDocument && trustDocument.documentId) return false
      
      return true
    },
    
    then: schema => schema.min(1, "Please upload at least one supporting document"),
  }),
  sourceOfFunds: string().required("Source of funds is required"),
})

export const InvestorQuestionnaireFormSchema = object({
  entityAccount: boolean().required(),
  mailingSameAsPermanentAddress: boolean().required(),
  mailingAddress: object({
    street1: string().required("Mailing address is required"),
    street2: string().optional(),
    city: string().required("City is required"),
    stateId: number().required("State is required"),
    zip: string().min(5, "Zip Code must be at least 5 digits").required("Zip Code is required"),
  }),
  fundSubscriber: number().when('entityAccount', {
    is: false,
    then: schema => schema.nullable()
  }).when('entityAccount', {
    is: true,
    then: schema => schema.required("Please select an option")
  }),
  authorizedRepresentatives: array().of(object({
    representativeContactId: string().required("Authorized representative is required"),
    representativeAddress: string().required("Address is required"),
    ssn: string().required("SSN/TIN is required"),
    dob: date().required("Date of Birth is required").nullable().typeError("Must be a valid date").max(new Date(), "Please pick a valid date"),
  })).optional(),
  optsOutBeneficialOwners: boolean().when('entityAccount', {
     is: true, 
     then: schema => schema.required() 
  }),
  beneficialOwners: array().when('entityAccount', {
    is: true,
    then: schema => schema.when('optsOutBeneficialOwners', {
      is: (value: any) => value !== true,
      then: schema => schema.of(object({
        ownerName: string().required("Beneficial owner name is required or check 'None'"),
        ownerAddress: string().required("Owner address is required"),
        ssn: string().required("SSN is required"),
        dob: date().typeError("Must be a valid date").max(new Date(), "Please pick a valid date").required("Date of birth is required"),
      })).min(1)
    })
  }),
  owners: array().of(object().shape({
    email: string().email().required(),
    ssn: string().required("SSN is required"),
    date_of_birth: string().required("Date of birth is required"),
    phone_number: array().of(object().shape({
      number: string().required("Phone number is required"),
    }))
  })),
  ein: string().when('entityAccount', {
    is: true,
    then: schema => schema.required("EIN is required"),
  }),
});

export const AccreditedInvestorFormSchema = object({
  accreditedAnswers: array().of(number()).min(1, "Please select at least one option")
})

export const DistributionInformationFormSchema = object({
  distributionInstructionType: number().required("Distribution type is required"),
  distributionInstructionId: number().required("Distribution Instruction is required"),
  wireInstructions: object().shape({}).when('distributionInstructionType', {
    is: 1,
    then: schema => schema.shape({
      instructionName: string().required("Wire name is required"),
      bankAccountName: string().required("Account name is required"),
      aba: string().required("Routing number is required"),
      accountNumber: string().required("Account number is required"),
      financialInstitutionName: string().optional(),
      financialStreetAddress1: string().optional(),
      financialStreetAddress2: string().optional(),
      financialCity: string().optional(),
      financialStateId: number().optional(),
      financialZip: string().min(5, "Zip Code must be at least 5 digits").optional(),
      ffc: string(),
      ffcAccountNumber: string(),
      verificationDocument: object({documentId: number(), documentName: string()}).nullable(),
    })
  }),
  brokerageInstructions: object().shape({}).when('distributionInstructionType', {
    is: 4,
    then: schema => schema.shape({
      instructionName: string().required("Wire name is required"),
      custodianId: number().required("Custodian is required"),
      bankAccountName: string().required("Account name is required"),
      aba: string().required("Routing number is required"),
      accountNumber: string().required("Account number is required"),
      financialInstitutionName: string().required("Financial institution name is required"),
      financialCity: string().required("Financial Institution City is required"),
      financialStateId: number().required("Financial Institution State is required"),
      ffc: string().required("FFC is required"),
      ffcAccountNumber: string().required("FFC Account number is required"),
      verificationDocument: object({documentId: number(), documentName: string()}).nullable(),
    })
  }),
  checkInstructions: object().shape({}).when('distributionInstructionType', {
    is: 2,
    then: schema => schema.shape({
      instructionName: string().required("Check Instruction name is required"),
      payeeName: string().required("Payee is required"),
      existingAddressId: number().nullable(),
      streetAddress1: string().required('Street address is required'),
      streetAddress2: string().optional(),
      city: string().required('City is required'),
      stateId: number().required('State is required'),
      zip: string().min(5, "Zip Code must be at least 5 digits").required("Required"),
    })
  }),
  achInstructions: object().shape({}).when('distributionInstructionType', {
    is: 3,
    then: schema => schema.shape({
      instructionName: string().required("ACH name is required"),
      bankAccountName: string().required('Bank Account Name is required'),
      aba: string().required('ABA is required'),
      accountNumber: string().optional(),
      ffc: string().optional(),
      accountType: number().required('Account type is required'),
      voidedCheck: object({documentId: number(), documentName: string()}).nullable()
    })
  }),
})

export const ElectronicDeliveryFormSchema = object({
  isElectronicDeliveryConfirmed: number().oneOf([0, 1]).required()
});

export const SupplementalDataFormSchema = (isTrustAccount: boolean) => object({
  dateOfIncorporation:date().typeError("Must be a valid date").required("Date of incorporation is required"),
  stateOfIncorporation: string().required("State of incorporation is required"),
  fundSpecificEntity:  boolean().nullable().required("Please select an option"),
  ...(isTrustAccount ? {
    revocable: string().required("Must select an option"),
    grantor: string().required("Grantor is required"),
  }: {}), 
  typeOfTrust: string().when('revocable', {
    is: RevocableStrings.Other,
    then: (schema) => schema.required("Trust type is required"),
  }),
  beneficiary: string().when('revocable', {
    is: RevocableStrings.Other,
    then: (schema) => schema.required("Beneficiary is required")
  }),
  trustees: string().when('revocable', {
    is: RevocableStrings.Other,
    then: (schema) => schema.required("Trustees are required")
  }),
})

export const PurchaseFundingInstructionFormSchema = object({
  isFunded: number().required("Funding status is required"),
  fundingType: string().when('isFunded', {
    is: 1,
    then: schema => schema.notRequired().nullable(),
    otherwise: schema => schema.required('Funding type is required')
  }),
  fundingDate: date().typeError("Must be a valid date").required().when('isFunded', {
    is: 0,
    then: schema => schema.required("Funding date is required").min(new Date(), "Please pick a date in the future"),
    otherwise: schema => schema.notRequired().nullable(),
  }),
  isOfficer: number().required("Investor description is required"),
  investorNatureOfRelationship: string().when('isOfficer', {
    is: 1,
    then: schema => schema.required("Nature of relationship is required"),
    otherwise: schema => schema.notRequired().nullable(),
  }),
  investorPercentOwned: number().notRequired().when('isOfficer', {
    is: 1,
    then: schema => schema.required("Percent owned is required").typeError("Percent owned must be a number"),
    otherwise: schema => schema.notRequired().nullable(),
  }),
})

export const ReviewStepFormSchema = object({
  advisorAcknowledgement: boolean().required("Please acknowledge the above information").notOneOf([false], "Please acknowledge the above information"),
});

export const initialAuthorizedRepresentativeValue: AuthorizedRepresentative = {
  representativeContactId: "",
  representativeAddress: "",
  ssn: "",
  dob: null
}

export const initialBeneficialOwner: BeneficialOwner = {
  ownerName: "",
  ownerAddress: "",
  ssn: "",
  dob: null
}

const initialInvestorQuestionnaireValues: InvestorQuestionnaireFormValues = {
  entityAccount: false,
  mailingSameAsPermanentAddress: false,
  mailingAddress: {
    street1: "",
    street2: "",
    city: "",
    stateId: "",
    zip: "",
  },
  fundSubscriber: null,
  authorizedRepresentatives: [],
  beneficialOwners: [{ownerName: "", ownerAddress: "", ssn: "", dob: null}],
  optsOutBeneficialOwners: false,
  owners: [],
  ein: "",
}

const initialAccreditedInvestorValues: AccreditedInvestorFormValues = {
  accreditedAnswers: []
}

const initialFinancialAdvisorValues: FinancialAdvisorFormValues = {
  firm: "",
  advisorId: "",
  advisoryAddress: {
    street1: "",
    street2: "",
    city: "",
    stateId: "",
    zip: ""
  },
  advisorEmail: "",
  advisorPhoneNumber: "",
}

const initialPurchaseFundingInstructionValues: PurchaseFundingInstructionFormValues = {
  isFunded: null,
  fundingDate: null,
  fundingType: "",
  isOfficer: null,
  investorNatureOfRelationship: "",
  investorPercentOwned: "",
}

const initialAMLVerificationValues = {
  formationDocuments: null,
  governingDocuments: null,
  trustDocuments: null,
  ownerIdDocuments: [],
  supportingDocuments: [],
  sourceOfFunds: "",
  otherSource: "",
}
export const initialSupportingDocument = null

export const initialDistributionInformationValues: DistributionInformationFormValues = {
  distributionInstructionType: DistributionInstructionType.Check,
  distributionInstructionId: "",
  wireInstructions: {
    instructionName: "",
    bankAccountName: "",
    aba: "",
    accountNumber: "",
    financialInstitutionName: "",
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    stateId: "",
    zip: "",
    financialStreetAddress1: "",
    financialStreetAddress2: "",
    financialCity: "",
    financialStateId: "",
    financialZip: "",
    ffc: "",
    ffcAccountNumber: "",
    verificationDocument: null
  },
  achInstructions: {
    instructionName: "",
    bankAccountName: "",
    aba: "",
    accountNumber: "",
    accountType: "",
    ffc: "",
    voidedCheck: null
  },
  checkInstructions: {
    instructionName: "",
    payeeName: "",
    existingAddressId: null,
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    stateId: "",
    zip: "", 
  },
  brokerageInstructions: {
    instructionName: "",
    custodianId: "",
    bankAccountName: "",
    aba: "",
    accountNumber: "",
    financialInstitutionName: "",
    financialCity: "",
    financialStateId: "",
    ffc: "",
    ffcAccountNumber: "",
    verificationDocument: null
  }
}

const initialRepresentationOfResidenceValues: RepresentationOfResidenceFormValues = {
  residenceAccountOwner: "",
  residenceCountryId: defaultCountryData, //TODO: Since this isn't for phone numbers and doesn't need the stop gap, rework simply be a country id
  residenceStateId: ""
}

const initialElectronicDeliveryValues: ElectronicDeliveryFormValues = {
  isElectronicDeliveryConfirmed: null,
}

const initialSupplementalValues: SupplementalDataFormValues = {
  dateOfIncorporation: null,
  stateOfIncorporation: "",
  fundSpecificEntity: null,
  revocable: "",
  typeOfTrust: "",
  grantor: "",
  beneficiary: "",
  trustees: ""
}

const initialReviewStepValues: ReviewStepFormValues = {
  advisorAcknowledgement: false
}

export const subDocsInitialValues: SubDocsFormValues  = {
  ...initialInvestorQuestionnaireValues,
  ...initialAccreditedInvestorValues,
  ...initialFinancialAdvisorValues,
  ...initialRepresentationOfResidenceValues,
  ...initialAMLVerificationValues,
  ...initialDistributionInformationValues,
  ...initialElectronicDeliveryValues,
  ...initialSupplementalValues,
  ...initialPurchaseFundingInstructionValues,
  ...initialReviewStepValues
}


export function parseSavedData(savedData: SubDocQuestionnaireDto) {
  const parsedData: SubDocsFormValues = {
    accreditedAnswers: savedData.accredited_investor_answers ?? [],
    advisorEmail: savedData.advisor_email ?? "",
    advisorId: savedData.professional_id ?? "",
    advisorPhoneNumber: savedData.advisor_phone ?? "",
    advisoryAddress: {
      city: savedData.advisory_address.city ?? "",
      stateId: savedData.advisory_address.stateId ?? "",
      street1: savedData.advisory_address.street1 ?? "",
      street2: savedData.advisory_address.street2 ?? "",
      zip: savedData.advisory_address.zip ?? "",
    },
    mailingSameAsPermanentAddress: savedData.mailing_same_as_permanent_address,
    mailingAddress: {
      street1: savedData.mailing_address.street1 ?? "",
      street2: savedData.mailing_address.street2 ?? "",
      city: savedData.mailing_address.city ?? "",
      stateId: savedData.mailing_address.stateId ?? "",
      zip: savedData.mailing_address.zip ?? "",
    },
    authorizedRepresentatives: savedData.authorized_representatives 
      ? savedData.authorized_representatives.map(r => ({
        representativeContactId: r.representativeContactId ?? "",
        dob: r.dateOfBirth ? moment(r.dateOfBirth) : null,
        representativeAddress: r.representativeAddress ?? "",
        ssn: r.ssn ?? "",
      }))
      : [],
    beneficialOwners: savedData.beneficial_owners 
      ? savedData.beneficial_owners.map(o => ({
        dob: o.dob ? moment(o.dob) : null,
        ownerAddress: o.ownerAddress ?? "",
        ownerName: o.ownerName ?? "",
        ssn: o.ssn ?? "",
        ...(o.contactId ? {contactId: o.contactId} : {}),
      }))
      : [],
    optsOutBeneficialOwners: savedData.opts_out_beneficial_owners ?? false,
    beneficiary: savedData.trust_beneficiaries ?? "",
    dateOfIncorporation: savedData.trust_date_of_incorporation ? moment(savedData.trust_date_of_incorporation) : null,
    distributionInstructionType: initialDistributionInformationValues.distributionInstructionType, //TODO: Expected to change with distribution overhaul
    distributionInstructionId: savedData.distribution_instruction_id ?? "",
    wireInstructions: {...initialDistributionInformationValues.wireInstructions},
    brokerageInstructions: {...initialDistributionInformationValues.brokerageInstructions},
    achInstructions: {...initialDistributionInformationValues.achInstructions},
    checkInstructions: {...initialDistributionInformationValues.checkInstructions},
    entityAccount: false, // May not matter what value we give it here
    firm: "",
    fundSpecificEntity: !isNullish(savedData.is_trust_fund_specific) ? (+savedData.is_trust_fund_specific as 0 | 1) : null,
    grantor: savedData.trust_grantors ?? "",
    isElectronicDeliveryConfirmed: !isNullish(savedData.is_electronic_consent_granted) ? (+savedData.is_electronic_consent_granted as 0 | 1) : null,
    residenceCountryId: defaultCountryData,
    residenceStateId: savedData.representation_of_residence_state_id ?? "",
    residenceAccountOwner: "",
    revocable: savedData.is_trust_revocable ? RevocableStrings.Revocable : !isNullish(savedData.is_trust_revocable) ? RevocableStrings.Other : "",
    stateOfIncorporation: savedData.trust_state_id ?? "",
    trustees: savedData.trustees ?? "",
    typeOfTrust: savedData.trust_type ?? "",
    fundSubscriber: !isNullish(savedData.is_fund_subscriber)? (+savedData.is_fund_subscriber as 0 | 1) : null,
    formationDocuments: null,
    governingDocuments: null,
    trustDocuments: null,
    ownerIdDocuments: [],
    supportingDocuments: [],
    isFunded: !isNullish(savedData.is_funded) ? (+savedData.is_funded as 0 | 1) : null,
    fundingDate: savedData.funding_date ? moment(savedData.funding_date) : null,
    fundingType: savedData.funding_type ?? "",
    isOfficer: !isNullish(savedData.is_officer) ? (+savedData.is_officer as 0 | 1) : null,
    investorNatureOfRelationship: savedData.investor_nature_of_relationship ?? "",
    investorPercentOwned: savedData.investor_percent_owned ?? "",
    sourceOfFunds: sourceOfFundsOptions.includes(savedData.source_of_funds) ? savedData.source_of_funds : !!savedData.source_of_funds ? 'Other' : '',
    otherSource: sourceOfFundsOptions.includes(savedData.source_of_funds) ? '' : savedData.source_of_funds ?? "",
  }

  return parsedData;
}

export const sourceOfFundsOptions = ["Capital Gains", "Wages", "Sale of Real Estate", "Sale of Investments", "Inheritance"]

