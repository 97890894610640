import { AutocompleteRenderInputParams, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { Autocomplete } from "formik-mui";
import React, { useCallback, useMemo } from "react";
import { Professional } from "../../../../api/payloads";
import { CornerCloseButton } from "../../../../components/Buttons";
import FormikField from "../../../../components/FormFields/FormikFields";
import { useProfessionals } from "../../../../hooks";
import { ShareClientWithProfessionalFormValues } from "../NonAdminAddProfDialog";

type NonAdminAddProfProps = {
  onClose: () => void;
};

const NonAdminAddProfContent: React.FC<NonAdminAddProfProps> = ({ onClose }) => {
  const { professional, professionalsList } = useProfessionals();
  const { values, touched, errors, setFieldValue } = useFormikContext<ShareClientWithProfessionalFormValues>();

  const filteredProfessionalsList = useMemo(() => {
    return professionalsList
      .filter((pro) => pro.id !== values.sourceProfessional?.id)
      .map(pro => ({
        label: `${pro.first_name} ${pro.last_name}`,
        value: pro.id,
      }));
  }, [professionalsList, values.sourceProfessional]);

  const handleSourceProfessionalChange = useCallback((newValue: Professional, values: ShareClientWithProfessionalFormValues) => {
    const { professionalsToLink } = values;
    const newProfessionalsToLink = professionalsToLink.filter((id) => id !== newValue.id);
    setFieldValue("sourceProfessional", newValue);
    setFieldValue("professionalsToLink", newProfessionalsToLink);
  }, [setFieldValue]);

  return (
    <>
      <DialogTitle sx={{ pt: 5 }}>
        Add Professional to Households
        <CornerCloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="inherit" gutterBottom sx={{ fontWeight: "bold", mt: 2 }}>
          Access to Clients:
        </Typography>
        {professional!.admin && (<>
          <Typography variant="body2" sx={{ fontStyle: "italic", mb: 1.5 }}>
            Select the professional whose clients you wish to share with other professionals.
          </Typography>

          <Field
            name="sourceProfessional"
            component={Autocomplete}
            autoHighlight
            openOnFocus
            options={professionalsList}
            getOptionLabel={(p: Professional) => `${p.first_name} ${p.last_name}`}
            isOptionEqualToValue={(option: Professional, value: Professional) => option.id === value.id}
            onChange={(e: any, v: Professional) => handleSourceProfessionalChange(v, values)}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                name="sourceProfessional"
                error={touched.sourceProfessional && !!errors.sourceProfessional}
                helperText={touched.sourceProfessional && errors.sourceProfessional ? String(errors.sourceProfessional) : " "}
                label="Source Professional"
                variant="outlined"
              />
            )}
            renderOption={(props: AutocompleteRenderInputParams, professional: Professional) => {
              return (
                <Typography {...props} key={professional.id}>{`${professional.first_name} ${professional.last_name}`}</Typography>
              );
            }}
          />

        </>)}
        <Typography variant="body2" gutterBottom sx={{ fontStyle: "italic" }}>
          {`Enter the name of the professional(s) that should be granted access to ${professional!.admin ? "the selected professional's" : "your"} households.`}
        </Typography>
        <Field
          name="professionalsToLink"
          label="Professional"
          component={FormikField}
          componentType="autocomplete"
          options={filteredProfessionalsList}
          getOptionDisabled={(option: { label: string, value: number }) => {
            return option.value === values.sourceProfessional?.id
          }
          }
          multiple
        />
        <Field
          name="receivesEmailNotices"
          label="Receives Email Notices for Associated Clients"
          component={FormikField}
          componentType="switch"
          customProps={{ color: "success" }}
          sx={{ mt: 2 }}
        />

        <Typography variant="subtitle2" gutterBottom sx={{ fontStyle: "italic", mt: 2 }}>
          Note: This action can only be undone by BIP Capital's Investor Success or your Firm's Administrator.
        </Typography>
      </DialogContent>
    </>
  )
};

export default NonAdminAddProfContent;
