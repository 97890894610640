import { TextField, MenuItem, Autocomplete, AutocompleteRenderInputParams, FormControlLabel, Switch, Typography } from "@mui/material";
import { FieldProps, getIn } from "formik";
import React, { useCallback } from "react";
import { useTheme } from "@mui/material";

type OptionType = {
  label: string;
  value: string | number;
};

type FormikFieldProps = FieldProps & {
  label: string;
  select?: boolean;
  options?: OptionType[];
  multiple?: boolean;
  componentType?: "textField" | "select" | "autocomplete" | "switch";
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  customProps?: Record<string, unknown>;
};

const FormikField: React.FC<FormikFieldProps> = ({
  field,
  form,
  label,
  select,
  options = [],
  multiple,
  componentType = "textField",
  renderInput,
  customProps = {},
  ...props
}) => {
  const { name, value } = field;
  const { touched, errors, setFieldValue, setFieldTouched } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;
  const theme = useTheme();

  const renderSwitchField = () => (
    <FormControlLabel
      control={
        <Switch
          checked={Boolean(value)}
          onChange={handleSwitchChange}
          name={name}
          color="primary"
          {...customProps}
          inputProps={{ "aria-label": label }}
        />}
      label={<Typography style={{ color: theme.palette.text.primary }}>{label}</Typography>}
    />
  );

  const handleSwitchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, event.target.checked);
  }, [name, setFieldValue]);

  const handleAutocompleteChange = useCallback((event: React.SyntheticEvent<Element, Event>, newValue: OptionType | OptionType[] | null) => {
    setFieldTouched(name, true);
    if (Array.isArray(newValue)) {
      setFieldValue(name, newValue.map((option) => option.value));
    } else if (newValue) {
      setFieldValue(name, [newValue.value]);
    } else {
      setFieldValue(name, []);
    }
  },
    [name, setFieldTouched, setFieldValue]
  );

  const renderTextField = () => (
    <TextField
      {...field}
      {...props}
      {...customProps}
      label={label}
      error={showError}
      helperText={showError ? fieldError : ""}
      variant="outlined"
      fullWidth
      inputProps={{ "aria-label": label }}
    />
  );

  const renderSelectField = () => (
    <TextField
      {...field}
      {...props}
      {...customProps}
      label={label}
      select
      error={showError}
      helperText={showError ? fieldError : ""}
      variant="outlined"
      fullWidth
      inputProps={{ "aria-label": label }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );

  const renderAutocompleteField = () => {
    const displayPlaceholder = !value || value.length === 0;
    return (
      <Autocomplete
        {...props}
        {...customProps}
        options={options}
        multiple={multiple}
        value={options.filter((option) => value.includes(option.value))}
        onChange={handleAutocompleteChange}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params: AutocompleteRenderInputParams) =>
          renderInput ? (
            renderInput(params)
          ) : (
            <TextField
              {...params}
              label={displayPlaceholder ? label : ""}
              placeholder={displayPlaceholder ? `Select ${label}` : ""}
              error={showError}
              helperText={showError ? fieldError : ""}
              variant="outlined"
              fullWidth
              inputProps={{
                ...params.inputProps,
                "aria-label": label,
              }}
            />
          )
        }
      />
    );
  };

  switch (componentType) {
    case "select":
      return renderSelectField();
    case "autocomplete":
      return renderAutocompleteField();
    case "switch":
      return renderSwitchField();
    default:
      return renderTextField();
  }
};

export default FormikField;
