import { Button, Dialog, DialogActions, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { FC } from "react";
import { array, object } from "yup";
import { apiClient } from "../../../api/apiClient";
import { PostGrantProfessionalAccessToClientsReq, Professional } from "../../../api/payloads";
import { ErrorContactEmail } from "../../../components/ErrorContactEmail/ErrorContactEmail";
import { useAuth, useClients, useProfessionals, useUI } from "../../../hooks";
import { isAxiosError } from "../../../utility/type-guards";
import NonAdminAddProfContent from "./DialogContent/NonAdminAddProfContent";

const validationSchema = object().shape({
  professionalsToLink: array().min(1, "At least one professional is required"),
  sourceProfessional: object().nullable().required(),
});

export type ShareClientWithProfessionalFormValues = {
  professionalsToLink: number[];
  sourceProfessional: Professional;
  receivesEmailNotices: 0 | 1 | boolean;
}

const NonAdminAddProfDialog: FC<{ open: boolean; onClose: (success?: boolean) => void }> = ({ open, onClose }) => {
  const { professionalsList, advisory, professional } = useProfessionals();
  const { clients } = useClients();
  const { user } = useAuth();
  const { setLoading, setError, setSuccess } = useUI();

  const initialValues: ShareClientWithProfessionalFormValues = {
    professionalsToLink: [],
    sourceProfessional: professional!,
    receivesEmailNotices: false,
  };

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      setLoading(true);

      if (!user || !advisory) return;

      const payload: PostGrantProfessionalAccessToClientsReq = {
        userProfessionalId: values.sourceProfessional.id,
        userAdvisoryId: advisory.id,
        professionalsToLink: values.professionalsToLink,
        receivesEmailNotices: values.receivesEmailNotices,
      };

      const response = await apiClient.post("/professionals/interested-parties/grant-access", { data: payload });
      const professionalsLinked = response.professionalsLinked;

      const successMessages = professionalsLinked.map(link => {
        const professional = professionalsList.find(pro => pro.id === link.professionalId);
        const professionalName = professional ? `${professional.first_name} ${professional.last_name}` : 'Unknown: Please contact support';
        return `Linked ${link.count} new household${link.count > 1 ? 's' : ''} to ${professionalName}`;
      });

      const successMessage = (
        <Typography variant="inherit" style={{ whiteSpace: 'pre-line' }}>
          {successMessages.length > 1
            ? successMessages.map((msg, index) => (
              <span key={index}>
                • {msg}
                <br />
              </span>
            ))
            : successMessages[0]
          }
        </Typography>
      );
      setSuccess(successMessage);
      onClose(true);
    } catch (error: any) {
      let message = 'We were unable to add the selected professionals';

      if (isAxiosError(error)) {
        message = error.response?.data?.message ?? error.message;
      }
      setError(
        <div>
          <Typography variant="h6" color="error">
            Something went wrong:
          </Typography>
          <Typography variant="body1">
            {message} Please contact <ErrorContactEmail /> for assistance.
          </Typography>
        </div>
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose()}>
      {clients.length > 0 ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {({ isValid }) => (
            <Form>
              <NonAdminAddProfContent onClose={onClose} />
              <DialogActions>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button onClick={() => onClose(false)} color="inherit">
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button type="submit" color="info" disabled={!isValid}>
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          )}
        </Formik>
      ) : (
        <Grid container justifyContent="center" alignItems="center" style={{ padding: "20px" }}>
          <Typography variant="body1" color="error">
            * This professional has no households to link.
          </Typography>
        </Grid>
      )}
    </Dialog>
  );
};

export default NonAdminAddProfDialog;
