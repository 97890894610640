import { Typography, Paper, Box, Grid, Link, Button, Divider } from "@mui/material";
import { useFormikContext } from "formik";
import { DistributionInstructionAccountType, DistributionInstructionType } from "../../../../../../utility/constants";
import gray from '@mui/material/colors/grey';
import { NewInstructionFormValues } from "../newInstructionValidationSchema";
import { InvestmentNameMapping } from "../ClientInstructionsStepper";
import { FC, useEffect, useState } from "react";
import { useClients } from "../../../../../../hooks/ContextHooks/useClients";
import { useClientDetails } from "../../../../../../hooks";
import { NewInstructionDisclaimer } from "../NewInstructionDisclaimer";


type DistributionInstructionReviewProps = {
  investmentNameMapping: InvestmentNameMapping;
  setIsContactInfoMissing: (isContactInfoMissing: boolean) => void;
};

export const DistributionInstructionReview: FC<DistributionInstructionReviewProps> = ({ setIsContactInfoMissing, investmentNameMapping }) => {
  const { values } = useFormikContext<NewInstructionFormValues>();
  const {addressStates, custodians} = useClients();
  const { activeClient, accountsMap } = useClientDetails();
  const [clientMemberOwners, setClientMemberOwners] = useState('');

  useEffect(() => {
    const formatContactOwners = () => {
      const selectedAccountIds = Object.keys(values.selected)
        .filter(key => values.selected[key] === true)
        .map(key => key.split('-')[0]);
      const uniqueOwners = new Map();
      let contactInfoMissing = false;
    
      activeClient?.accounts.forEach(account => {
        if (selectedAccountIds.includes(account.id.toString())) {
          account.contact_owners.forEach(owner => {
            if (!owner.email || owner.phone_numbers.length === 0) {
              contactInfoMissing = true;
            }

            const mobilePhoneNumber = owner.phone_numbers.find(pn => pn.contact_number_type.toLowerCase() === "mobile");
            const fallbackPhoneNumber = owner.phone_numbers[0];
            const selectedPhoneNumber = mobilePhoneNumber || fallbackPhoneNumber;
            const phoneNumber = selectedPhoneNumber ? `${selectedPhoneNumber.number} (${selectedPhoneNumber.contact_number_type})` : `<span style="color: red;">Phone number not available</span>`;
            const email = owner.email || `<span style="color: red;">Email not available</span>`;
    
            if (!uniqueOwners.has(owner.name)) {
              uniqueOwners.set(owner.name, {
                email: email,
                phoneNumber: phoneNumber
              });
            }
          });
        }
      });
      setIsContactInfoMissing(contactInfoMissing);
      return Array.from(uniqueOwners).map(([name, details]) => {
        return `<div>Owner Name: <strong>${name}</strong><br>Email: <strong>${details.email}</strong><br>Phone Number: <strong>${details.phoneNumber}</strong></div>`;
      }).join('<br>');
    };
    setClientMemberOwners(formatContactOwners());    
  }, [values.selected, activeClient, setIsContactInfoMissing]);

  const getStateNameById = (stateId: number | '') => {
    const state = addressStates?.find(s => s.id === stateId);
    return state ? state.name : '';
  };
  const getAccountTypeNameById = (accountTypeId: number | '') => {
    const accountType = Object.entries(DistributionInstructionAccountType).find(([key, value]) => value === accountTypeId);
    const name = accountType?.[0] || '';
    return name;
  };

  const getCustodianNameById = (custodianId: number | '') => {
    const custodian = custodians.find(c => c.id === custodianId);
    return custodian ? custodian.name : '';
  };

  const processSelectedItems = () => {
    const selectedAccounts = [];
    const selectedInvestments = [];
  
    for (const key in values.selected) {
      if (values.selected[key]) {
        const [accountId, investmentCompanyId] = key.split('-');
        if (investmentCompanyId) { 
          const investment = investmentNameMapping[key];
          selectedInvestments.push(investment?.investment_name || key);
        } else {
          const accountName = accountsMap[+accountId].account_name;
          selectedAccounts.push(accountName || accountId);
        }
      }
    }
    return {
      accounts: selectedAccounts.join(', '),
      investments: selectedInvestments.join(', ')
    };
  };

  const instructionTypeDetails = () => {
    const { accounts, investments } = processSelectedItems();
      switch (values.instructionType) {
        case DistributionInstructionType.Wire:
          return {
            "Name": values.wireInstructions?.instructionName || '',
            "Instruction Type": 'Wire',
            "Name on Bank Account": values.wireInstructions?.bankAccountName || '',
            "Bank Account Number": values.wireInstructions?.accountNumber || '',
            "Bank Routing Number": values.wireInstructions?.aba || '',
            "Financial Institution Name": values.wireInstructions?.financialInstitutionName || '',
            "Financial Institution Street Address": values.wireInstructions?.financialStreetAddress1 || '',
            "Financial Institution Street Address 2": values.wireInstructions?.financialStreetAddress2 || '',
            "Financial Institution City": values.wireInstructions?.financialCity || '',
            "Financial Institution State": values.wireInstructions?.financialStateId ? getStateNameById(values.wireInstructions.financialStateId) : '',
            "Financial Institution Zip": values.wireInstructions?.financialZip || '',
            "Reference (For Further Credit)": values.wireInstructions?.ffc || '',
            "FFC to Account Number" : values.wireInstructions?.ffcAccountNumber || '',
            "Investor Primary Address": values.wireInstructions?.streetAddress1 || '',
            "Street Address 2": values.wireInstructions?.streetAddress2 || '',
            "City": values.wireInstructions?.city || '',
            "State": getStateNameById(values.wireInstructions?.stateId) || '',
            "Zip": values.wireInstructions?.zip || '',
            "Accounts": accounts,
            "Investments": investments,
            "Verification Document": values.wireInstructions?.verificationDocument || null,
            "Client Member/Owner(s)": clientMemberOwners,
            
          };
        case DistributionInstructionType.Check:
          return {
            "Name": values.checkInstructions?.instructionName || '',
            "Instruction Type": 'Check',
            "Payee Name": values.checkInstructions?.payeeName || '',
            "Street Address": values.checkInstructions?.streetAddress1 || '',
            "Street Address 2": values.checkInstructions?.streetAddress2 || '',
            "City": values.checkInstructions?.city || '',
            "State": getStateNameById(values.checkInstructions?.stateId) || '',
            "Zip": values.checkInstructions?.zip || '',
            "Accounts": accounts,
            "Investments": investments,
            "Client Member/Owner(s)": clientMemberOwners,
          };
        case DistributionInstructionType.ACH:
          return {
            "Name": values.achInstructions?.instructionName || '',
            "Instruction Type": 'ACH',
            "Name on Bank Account": values.achInstructions?.bankAccountName || '',
            "Bank Account Number": values.achInstructions?.accountNumber || '',
            "Bank Routing Number": values.achInstructions?.aba || '',
            "Account Type": getAccountTypeNameById(values.achInstructions?.accountType) || '',
            "For Further Credit To": values.achInstructions?.ffc || '',
            "Accounts": accounts,
            "Investments": investments,
            "Voided Check": values.achInstructions?.voidedCheck || null,
            "Client Member/Owner(s)": clientMemberOwners,
            
          };
        case DistributionInstructionType.Brokerage:
          return {
            "Name": values.brokerageInstructions?.instructionName || '',
            "Instruction Type": 'Brokerage',
            "Name on Bank Account": values.brokerageInstructions?.bankAccountName || '',
            "Custodian": getCustodianNameById(values.brokerageInstructions?.custodianId) || '',
            "Bank Account Number": values.brokerageInstructions?.accountNumber || '',
            "Bank Routing Number": values.brokerageInstructions?.aba || '',
            "Financial Institution Name": values.brokerageInstructions?.financialInstitutionName || '',
            "Financial Institution City": values.brokerageInstructions?.financialCity || '',
            "Financial Institution State": getStateNameById(values.brokerageInstructions?.financialStateId) || '',
            "Reference (For Further Credit)": values.brokerageInstructions?.ffc || '',
            "FFC to Account Number" : values.brokerageInstructions?.ffcAccountNumber || '',
            "Accounts": accounts,
            "Investments": investments,
            "Verification Document": values.brokerageInstructions?.verificationDocument || null,
            "Client Member/Owner(s)": clientMemberOwners,
          };
        default:
          return {};
      }
    };
    const openDocument = (file: File) => {
      if (file instanceof File) {
        const url = URL.createObjectURL(file);
        window.open(url, '_blank');
      } else {
        console.error("Invalid file object:", file);
      }
    };
  
  const detailsToDisplay = instructionTypeDetails();
    
  return (
    <Paper elevation={3} sx={{ p: 4, mb: 2, mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Review Distribution Instruction Details
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant="body2" gutterBottom sx={{ mt: 2, color: gray[600], mb: 2 }}>
        Review the information entered for the Distribution Instruction plus the Account(s) and Investment(s) to be linked. Click the “Back” button to make any necessary changes. When ready, click the “Send to Client” button to generate a confirmation document via DocuSign. This document will be sent to the client member/owner(s) for review and approval via DocuSign.
      </Typography>
      <NewInstructionDisclaimer/>
        <Paper elevation={3} sx={{ p: 4, marginBottom: 2 , marginTop: 2}}>
          {Object.entries(detailsToDisplay).map(([label, value]) => (
            <Grid container spacing={2} key={label}>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle1" gutterBottom sx={{color: gray[600]}}>
                  {label}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={8}>
                <Typography component='div' variant="body1" gutterBottom sx={{color: gray[500]}}>
                  {label === 'Client Member/Owner(s)' ? <div dangerouslySetInnerHTML={{ __html: clientMemberOwners }} /> : 
                    (label.includes("Document") || label.includes("Check") ? 
                      <Button onClick={() => openDocument(value)} variant="text">{value?.name || 'View Document'}</Button> : value)}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Paper>
    </Paper>
  );
};