import { Search } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputAdornment, Link, Menu, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { grey, yellow } from "@mui/material/colors";
import moment from "moment";
import React, { ChangeEvent, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apiClient } from "../../../api/apiClient";
import { EnvelopeStatus, InterestedClient, SubscriptionAmountUpdate } from "../../../api/payloads";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { ColumnDef, DataSourceTable, RowState } from "../../../components/DataSourceTable";
import CurrencyFieldText from "../../../components/FormFields/CurrencyField";
import { TableTooltip } from "../../../components/TableTooltip/Tabletooltip";
import { useAuth, useClients, useInvestments, useMaskedParams, useUI } from "../../../hooks";
import useBoolean from "../../../hooks/useBoolean";
import { ApplicationSource, FundStatus, InspiraFinancialTrustCustodianId, SubDocStatus } from "../../../utility/constants";
import { formatDate } from "../../../utility/date.util";
import { isNullish } from "../../../utility/misc.util";
import { toCurrency } from "../../../utility/number.util";
import { maskRouteId } from "../../../utility/route-mask.util";
import { getSubDocDto } from "../Subdoc/Forms/subDocForm.util";
import { subDocsInitialValues } from "../Subdoc/Forms/subDocsFormSchema";
import SubDocDownloadHandler from "./DocumentsTab/SubDocDownloadHandler";
import { InvestorDialog } from "./InvestorDialog";

export type FilterOption = {
  value: number;
  label: string | ReactNode;
}

type FilterOptions = {
  "Sub Docs Status": FilterOption[],
  "Fund Status": FilterOption[],
  "Monies Status": FilterOption[],
  "Accreditation Status": FilterOption[],
}

enum MoniesStatus {
  NotReceived = 1,
  Received = 2,
}

enum AccreditationStatus {
  NotStarted = 1,
  Completed = 2,
}

export enum RecipientStatus {
  Signed = 'signed',
  Completed = 'completed',
}

export const InterestedList: FC = () => {
  const { interestedList, setInterestedList, prospectsList, setProspectsList, investedList } = useInvestments();
  const { investmentId } = useMaskedParams();
  const [searchFilter, setSearchFilter] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [rowId, setRowId] = useState<number | null>(null);
  const [subscriptionAmount, setSubscriptionAmount] = useState<number | null>(null);
  const { setError, setLoading, setSuccess } = useUI()
  const [selectedCategory, setSelectedCategory] = useState<keyof FilterOptions>('Sub Docs Status');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = React.useState<InterestedClient | null>(null);
  const [envelopeStatusData, setEnvelopeStatusData] = useState<EnvelopeStatus[]>([]);
  const [dialogs, setDialogs] = useState({ isAddInvestorDialogOpen: false, isEnvelopeStatusDialogOpen: false, isConfirmationDialogOpen: false });
  const [confirmationDialogConfig, setConfirmationDialogConfig] = React.useState<{ title: string, message: string } | null>(null);
  const { setTrue: setRefreshDataTrue } = useBoolean(true);
  const { clientsMap } = useClients();
  const MIN_INITIAL_INVESTMENT = 10000;
  const MIN_SUBSEQUENT_INVESTMENT = 5000;
  const [rowState, setRowState] = useState<{ [key: number]: { isHighlighted: boolean } }>({});

  useEffect(() => {
    if (location.state?.highlightedRowId) {
      setRowState((prevState) => ({
        ...prevState,
        [location.state.highlightedRowId]: { isHighlighted: true },
      }));
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state?.highlightedRowId, navigate, location.pathname]);

  const handleRowStateChange = (rowId: string | number, newState: Partial<RowState[number]>) => {
    setRowState((prevState) => ({
      ...prevState,
      [rowId]: {
        ...prevState[+rowId],
        ...newState,
      },
    }));
  };

  const openDialog = (dialog: keyof typeof dialogs) => {
    setDialogs((prev) => ({ ...prev, [dialog]: true }));
  };

  const closeDialog = (dialog: keyof typeof dialogs) => {
    setDialogs((prev) => ({ ...prev, [dialog]: false }));
  };

  const handleOpenEnvelopeStatusDialog = () => {
    openDialog('isEnvelopeStatusDialogOpen');
  };

  const handleCloseEnvelopeStatusDialog = () => {
    closeDialog('isEnvelopeStatusDialogOpen');
  };

  const handleOpenConfirmationDialog = (row: InterestedClient | null, title: string, message: string) => {
    if (!row) return;
    setSelectedRow(row);
    setConfirmationDialogConfig({ title, message });
    openDialog('isConfirmationDialogOpen');
  };

  const handleCloseConfirmationDialog = async (success?: boolean) => {
    if (!confirmationDialogConfig || !selectedRow) return;
    closeDialog('isConfirmationDialogOpen');
    setConfirmationDialogConfig(null);
    setSelectedRow(null);
    if (success) {
      const { title } = confirmationDialogConfig;
      if (title === "Delete") handleDelete();
      if (title === "Void") handleVoid();
    }
  };

  const isProspectRow = (row: InterestedClient) => row.account_id === null;  // Using the absence of an account_id to determine if a row is a prospect

  const combinedList: InterestedClient[] = useMemo(() => {
    const formattedProspects = prospectsList.map(prospect => ({
      ...prospect,
      account_id: null,
      account_name: '',
      account_type_id: null,
      custodian_id: null,
      subscription_amount: 0,
      shares: null,
      accreditation_id: null,
      sub_doc_delivered_timestamp: null,
      sub_doc_questionnaire_response_id: null,
      sub_doc_submission_health: null,
      docs_received_date: null,
      fund_status: FundStatus.NotSubmitted,
      document_id: null,
      position_id: null,
      monies_received_date: null,
      approved_signer: false,
    }));
    const mergedList = [...interestedList, ...formattedProspects];
    return mergedList.sort((a, b) => +new Date(a.created) - +new Date(b.created));
  }, [interestedList, prospectsList]);

  const fetchInterestListData = useCallback(async () => {
    try {
      const transactions = await apiClient.get(`/investors/investment-companies/:p0/transactions`, {
        routeParams: [investmentId],
      });
      setInterestedList(transactions.interestedList);
      setProspectsList(transactions.prospectsList);
    } catch (error) {
      console.error("Failed to fetch interest list data:", error);
      setError("Failed to refresh the interest list data.");
    }
  }, [investmentId, setInterestedList, setProspectsList, setError]);

  useEffect(() => {
    fetchInterestListData();
  }, [investmentId, fetchInterestListData]);

  const handleClick = async (subDocQuestionnaireId: number) => {
    try {
      setLoading(true);

      const response = await apiClient.get(`/docusign/sub-doc/:p0`, { routeParams: [subDocQuestionnaireId] });

      const envelopeStatusData = response.envelopeStatus
      if (Array.isArray(envelopeStatusData)) {
        setEnvelopeStatusData(envelopeStatusData);
        handleOpenEnvelopeStatusDialog();
      } else {
        setError("Invalid data format received");
      }
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      } else if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An error occurred while fetching the envelope status.");
      }
    } 
    finally {
      setLoading(false);
    } 
  };

  const handleVoid = async () => {
    try {
      if (!selectedRow || selectedRow.sub_doc_questionnaire_response_id === null) return;

      const routeParams: number[] = [selectedRow.sub_doc_questionnaire_response_id];
      const voidReason: string = "Voided by Investor";

      await apiClient.patch('/docusign/sub-doc/void/:p0', {
        routeParams,
        data: { voidReason }
      });
      const updatedInterestedList = interestedList.map(row => {
        if (row.sub_doc_questionnaire_response_id === selectedRow.sub_doc_questionnaire_response_id) {
          return {
            ...row,
            sub_doc_submission_health: SubDocStatus.InProgress
          };
        }
        return row;
      });
      setInterestedList(updatedInterestedList);
      setSuccess("Envelope voided successfully");
      setTimeout(() => {
        setSuccess('');
      }, 3000);
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      } else if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An error occurred while voiding the envelope.");
      }
    }
  };

  const handleDelete = async () => {
    if (!selectedRow) return;

    if (!isProspectRow(selectedRow)) {
      apiClient.delete(`/investors/transactions/:p0`, { routeParams: [selectedRow.id] })
        .then(() => {
          setInterestedList(list => list.filter(row => row.id !== selectedRow.id));
          setSuccess("Investor deleted successfully");
        })
        .catch(error => {
          setError(error.message || "An error occurred while deleting the record.");
        });
    } else {
      apiClient.delete(`/investors/bdc-prospects/:p0`, { routeParams: [selectedRow.id] })
        .then(() => {
          setProspectsList(list => list.filter(row => row.id !== selectedRow.id));
          setSuccess("Investor deleted successfully");
          setSelectedRow(null);
        })
        .catch(error => {
          setError(error.message || "An error occurred while deleting the prospect.");
        });
    }
  }

  const handleResend = async () => {
    try {
      if (!selectedRow || selectedRow.sub_doc_questionnaire_response_id === null) return;

      const routeParams: number[] = [selectedRow.sub_doc_questionnaire_response_id];

      await apiClient.post('/docusign/sub-doc/resend/:p0', {
        routeParams,
      });
      setSuccess("DocuSign resent successfully");
      setTimeout(() => {
        setSuccess('');
      }, 3000);
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      } else if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An error occurred while resending the envelope.");
      }
    }
  };

  const approvedSigner = useMemo(() => {
    if (interestedList.length === 0) {
      return false
    } else {
      return interestedList[0].approved_signer
    }
  }, [interestedList])
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<number[]>(filterOptions(approvedSigner)[selectedCategory].map((option) => option.value));

  const { user } = useAuth()

  const handleFilterChange = (checked: boolean, selection: FilterOption) => {
    if (!checked) {
      setSelectedFilterOptions((prevState: number[]) => (prevState.filter((value) => {
        return value !== selection.value
      })));
      return;
    } else {
      setSelectedFilterOptions((prevState: number[]) => {
        return ([...prevState, selection.value])
      });
    }
  };

  const handleOptionChange = (event: SelectChangeEvent<number[]>) => {
    setSelectedFilterOptions(event.target.value as number[]);
  };

  const handleCategoryChange = (event: SelectChangeEvent<keyof FilterOptions>) => {
    setSelectedCategory(event.target.value as keyof FilterOptions);
    setSelectedFilterOptions(filterOptions(approvedSigner)[event.target.value as keyof FilterOptions].map((option) => option.value));
  }

  const handleClickEllipsis = (event: React.MouseEvent<HTMLElement>, row: InterestedClient) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleCloseEllipsis = () => {
    setAnchorEl(null);
  };


  const filteredRows = useMemo(() => {
    return combinedList.filter((row) => {
      switch (selectedCategory) {
        case 'Sub Docs Status':
          switch (row.sub_doc_submission_health) {
            case null:
              return selectedFilterOptions.includes(SubDocStatus.NotStarted);
            case SubDocStatus.InProgress:
              return selectedFilterOptions.includes(SubDocStatus.InProgress);
            case SubDocStatus.AwaitingSignature:
              return selectedFilterOptions.includes(SubDocStatus.AwaitingSignature);
            case SubDocStatus.Signed:
              return selectedFilterOptions.includes(SubDocStatus.Signed);
            case SubDocStatus.AwaitingApproval:
              return selectedFilterOptions.includes(SubDocStatus.AwaitingApproval);
            case SubDocStatus.Approved:
              return selectedFilterOptions.includes(SubDocStatus.Approved);
            default:
              return false;
          }
        case 'Fund Status':
          switch (row.fund_status) {
            case FundStatus.NotSubmitted:
              return selectedFilterOptions.includes(FundStatus.NotSubmitted);
            case FundStatus.Submitted:
              return selectedFilterOptions.includes(FundStatus.Submitted);
            case FundStatus.Pending:
              return selectedFilterOptions.includes(FundStatus.Pending);
            case FundStatus.Approved:
              return selectedFilterOptions.includes(FundStatus.Approved);
            case FundStatus.Rejected:
              return selectedFilterOptions.includes(FundStatus.Rejected);
            case FundStatus.Rolled:
              return selectedFilterOptions.includes(FundStatus.Rolled);

            default:
              return false;
          }
        case 'Monies Status':
          if (!isNullish(row.monies_received_date)) {
            return selectedFilterOptions.includes(MoniesStatus.Received);
          } else {
            return selectedFilterOptions.includes(MoniesStatus.NotReceived);
          }
        case 'Accreditation Status':
          if (row.accreditation_id) {
            return selectedFilterOptions.includes(AccreditationStatus.Completed);
          } else {
            return selectedFilterOptions.includes(AccreditationStatus.NotStarted);
          }
        default:
          return false;
      }
    });
  }, [combinedList, selectedCategory, selectedFilterOptions]);

  const handleSubDocsClick = async (row: InterestedClient) => {
    if (row.sub_doc_questionnaire_response_id) {
      navigate(`../sub-doc/${maskRouteId(row.sub_doc_questionnaire_response_id)}`)
    } else {
      const routeParams = [+row.id!];
      const data = getSubDocDto(subDocsInitialValues, 0);
      const subDocQuestionnaireResp = await apiClient.post('/investors/transactions/:p0/sub-doc', { data, routeParams });
      navigate(`../sub-doc/${maskRouteId(subDocQuestionnaireResp.subDocQuestionnaireId)}`)
    }
  }

  const handleAccreditation = (row: InterestedClient) => {
    navigate(`../client/${maskRouteId(row.client_id)}/accredited/${maskRouteId(row.account_id)}/transactions/${maskRouteId(row.id)}`)
  }

  const handleSave = async (row: InterestedClient) => {
    try {
      setLoading(true)
      if (!subscriptionAmount) return;

      const minimumAmount = row?.shares ? MIN_INITIAL_INVESTMENT : MIN_SUBSEQUENT_INVESTMENT;

      if (subscriptionAmount < minimumAmount) {
        setError(row?.shares
          ? `Initial Investment must be at least ${toCurrency(minimumAmount)}`
          : `Subsequent Investment must be at least ${toCurrency(minimumAmount)}`);
        return;
      }

      const routeParams = [rowId!]
      const payload: SubscriptionAmountUpdate = {
        subscriptionAmount,
        fundId: investmentId,
        professionalId: user?.id
      }
      await apiClient.patch('/investors/transactions/:p0', { data: payload, routeParams });
      setInterestedList((prevList) =>
        prevList.map((item) => item.id === row.id ? { ...item, subscription_amount: subscriptionAmount } : item)
      );
      setSuccess("Subscription Amount Updated")
    } catch (error) {
      setError("Error While Updating")
    } finally {
      setRowId(null)
      setLoading(false)
    }
  };

  const handleClose = () => {
    setSubscriptionAmount(null);
    setRowId(null);
  };


  const displayEditableCurrency = (v: number | string | null, row: InterestedClient) => {
    if (isProspectRow(row)) return '$0';

    const viewBox = (
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          setRowId(row?.id);
          setSubscriptionAmount(null);
        }}
      >
        <Link>{v !== null ? toCurrency(v) : '---'}</Link>
      </Box>
    );

    const minimumAmount = row?.shares ? MIN_INITIAL_INVESTMENT : MIN_SUBSEQUENT_INVESTMENT;

    const editBox = (
      <Box sx={{ display: 'flex', alignItems: "center" }}>
        <CurrencyFieldText
          sx={{ width: '90px', p: 0, mr: 1, height: 40 }}
          defaultValue={v?.toLocaleString()}
          variant="standard"
          onValueChange={(val: any) => setSubscriptionAmount(val.floatValue)}
        />
        <IconButton disabled={!isNullish(subscriptionAmount) && (subscriptionAmount < minimumAmount)} onClick={() => handleSave(row)}>
          <CheckIcon fontSize="small" sx={{ color: !isNullish(subscriptionAmount) && (subscriptionAmount < minimumAmount) ? "grey" : 'green', m: 0 }} />
        </IconButton>
        <IconButton onClick={handleClose}>
          <CloseIcon fontSize="small" sx={{ color: "red", m: 0 }} />
        </IconButton>
      </Box>
    )
    if (v == null) return '---';

    if (row.sub_doc_submission_health === SubDocStatus.InProgress || !row.sub_doc_questionnaire_response_id) {
      return (
        <Box sx={{ position: 'relative' }}>
          {rowId === row.id ?
            <>
              {editBox}
              {!isNullish(subscriptionAmount) && (subscriptionAmount < minimumAmount) && <Box sx={{ position: 'absolute', bottom: -14 }}>
                {minimumAmount === MIN_INITIAL_INVESTMENT ?
                  <Typography sx={{ color: 'red', fontSize: 12 }}>(No less than $10,000)</Typography>
                  :
                  <Typography sx={{ color: 'red', fontSize: 12 }} >(No less than $5,000)</Typography>
                }
              </Box>}
            </> :
            (viewBox)}
        </Box>
      )
    } else {
      return toCurrency(v);
    }
  };

  const handleOpenAddInvestorDialog = (row: InterestedClient) => {
    setSelectedRow(row);
    openDialog('isAddInvestorDialogOpen');
  };

  const displayAccountName = (v: string | null, row: InterestedClient) => {
    if (!isProspectRow(row)) return v || '---';
    else {
      return (
        <Button
          variant="text"
          onClick={() => handleOpenAddInvestorDialog(row)}
          sx={{
            color: 'warning.main',
            textTransform: 'none',
            padding: 0,
            '&:hover': { backgroundColor: 'transparent' },
          }}
        >
          Account Not Linked
        </Button>
      );
    }
  };

  const displayClientName = (v: string | null, row: InterestedClient) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
        {row.application_source_id === ApplicationSource.Vision && (
          <Box sx={{
            width: 8,
            height: 8,
            backgroundColor: 'orange',
            borderRadius: '50%',
            marginRight: 1,
          }} />
        )}
        {v}
      </Box>
    );
  };

  const handleCloseAddInvestorDialog = async (result?: boolean, payload?: Partial<InterestedClient>) => {
    closeDialog('isAddInvestorDialogOpen');

    if (result) {
      await fetchInterestListData();
      setSuccess("Investor added successfully");
    }
    setSelectedRow(null);
  };

  const displayStatus = (docsReceived: boolean | null, row: InterestedClient) => {
    if (isProspectRow(row)) {
      return (
        <Button disabled color="tertiary" sx={{ px: 1, py: 0, minWidth: '78px' }}>Start Now</Button>
      );
    }

    const { sub_doc_questionnaire_response_id, sub_doc_submission_health, document_id, account_type_id, custodian_id, accreditation_id } = row;
    const isCustodiedAccountOrNonInspiraIra = !!custodian_id && custodian_id !== InspiraFinancialTrustCustodianId;
    const isAccreditationCompleted = accreditation_id !== null;
    const renderButton = (label: string, onClick: () => void) => (
      <Button
        color='tertiary'
        onClick={onClick}
        sx={{ px: 1, py: 0, minWidth: '78px' }}
      >
        {label}
      </Button>
    );
    if (!sub_doc_questionnaire_response_id) {
      return renderButton("Start Now", () => handleSubDocsClick(row))
    }

    switch (sub_doc_submission_health) {
      case SubDocStatus.InProgress:
        return renderButton("Resume", () => handleSubDocsClick(row));
      case SubDocStatus.AwaitingSignature:
        return (
          <>
            <Chip
              color="attention"
              clickable
              size="small"
              label="Awaiting Signature"
              variant="filled"
              onClick={() => handleClick(sub_doc_questionnaire_response_id)}
            />

          </>
        );
      case SubDocStatus.Signed:
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box component="span" color="secondary.main" sx={{ marginRight: '4px' }}>
              {SubDocStatusChips.Signed}
            </Box>
            {isCustodiedAccountOrNonInspiraIra && document_id && (
              <TableTooltip arrow title={!isAccreditationCompleted ? (
                <Typography variant="body2" px='0.5rem' py='0.25rem'>
                  Must complete Investor Accreditation before downloading
                </Typography>
              ) : (
                <Typography variant="body2" px='0.5rem' py='0.25rem'>
                  Document available for download
                </Typography>
              )}>
                <span>
                  <SubDocDownloadHandler
                    documentId={document_id}
                    subDocId={sub_doc_questionnaire_response_id}
                    isAccreditationCompleted={isAccreditationCompleted}
                    variant="text"
                    icon={<CloudDownloadIcon style={{ fontSize: '1rem', width: '1rem' }} />}
                  />
                </span>
              </TableTooltip>
            )}
          </Box>
        );
      case SubDocStatus.AwaitingApproval:
        return (
          <TableTooltip arrow title={(
            <Typography variant="body2" px='0.5rem' py='0.25rem'>
              {`Submitted on ${formatDate(row.sub_doc_delivered_timestamp, { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}`}
            </Typography>)
          }>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box component="span" color="secondary.main" sx={{ marginRight: '4px' }}>
                {SubDocStatusChips.AwaitingApproval}
              </Box>
              {document_id && (
                <SubDocDownloadHandler documentId={document_id} subDocId={sub_doc_questionnaire_response_id} isAccreditationCompleted={isAccreditationCompleted} variant="text"
                  icon={<CloudDownloadIcon style={{ fontSize: '1rem', width: '1rem' }} />} />
              )}
            </Box>
          </TableTooltip>
        );
      case SubDocStatus.Approved:
        return (
          <TableTooltip arrow title={(
            <Typography variant="body2" px='0.5rem' py='0.25rem'>
              {`Approved on ${formatDate(row.docs_received_date, { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}`}
            </Typography>)
          }>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box component="span" color="secondary.main" sx={{ marginRight: '4px' }}>
                {SubDocStatusChips.Approved}
              </Box>
              {document_id && (
                <SubDocDownloadHandler documentId={document_id} subDocId={sub_doc_questionnaire_response_id} isAccreditationCompleted={isAccreditationCompleted} variant="text"
                  icon={<CloudDownloadIcon style={{ fontSize: '1rem', width: '1rem' }} />} />
              )}
            </Box>
          </TableTooltip>
        );
      default:
        break;
    }
  }

  const displayMoniesStatus = (v: string | number | null, row: InterestedClient) => {
    if (isProspectRow(row)) return MoniesStatusChips.NotReceived;

    return v != null ? MoniesStatusChips.Received : MoniesStatusChips.NotReceived;
  };

  const displayFundStatus = (v: string | number | null, row: InterestedClient) => {
    if (isProspectRow(row)) return FundStatusChips.NotSubmitted;

    switch (v) {
      case FundStatus.NotSubmitted:
        return FundStatusChips.NotSubmitted
      case FundStatus.Submitted:
        return FundStatusChips.Submitted
      case FundStatus.Pending:
        return FundStatusChips.Pending
      case FundStatus.Approved:
        return FundStatusChips.Approved
      case FundStatus.Rejected:
        return FundStatusChips.Rejected
      case FundStatus.Rolled:
        return FundStatusChips.Rolled
      case FundStatus.Withdrawn:
        return FundStatusChips.Withdrawn
      default:
        return ""
    }
  }

  const displayAccreditationStatus = (v: boolean, row: InterestedClient) => {
    if (isProspectRow(row)) return (<Button disabled color="tertiary" sx={{ p: 1, pt: 0, pb: 0 }}> Start Now</Button>);

    if (v) {
      return row.accreditation_id
        ? AccreditationStatusChips.Completed
        : <Button onClick={() => handleAccreditation(row)} color="tertiary" sx={{ p: 1, pt: 0, pb: 0 }}>Start Now</Button>;
    } else {
      return row.accreditation_id
        ? AccreditationStatusChips.Completed
        : AccreditationStatusChips.NotStarted;
    }
  }

  const distributionsColumnDef: ColumnDef<InterestedClient & { actions: never }> = [
    { id: 'client_name', label: "Client Name", displayFn: (v: string | null, row: InterestedClient) => displayClientName(v, row) },
    { id: 'account_name', label: "Account Name", displayFn: (v, row) => displayAccountName(v, row) },
    { id: 'subscription_amount', label: "Subscription Amount", displayFn: (v: number | string | null, row: InterestedClient) => displayEditableCurrency(v, row), showTotal: true, totalDisplayFn: (v: number | string) => toCurrency(v) },
    { id: 'docs_received_date', label: "Sub Docs Status", displayFn: displayStatus, },
    { id: 'approved_signer', label: "Accreditation Status", displayFn: displayAccreditationStatus },
    { id: 'monies_received_date', label: "Monies Status", displayFn: displayMoniesStatus },
    { id: 'fund_status', label: "Fund Status", displayFn: displayFundStatus },
    {
      id: 'actions', label: "Actions", displayFn: (_, row: InterestedClient) => (
        <React.Fragment>
          <IconButton
            onClick={(e) => handleClickEllipsis(e, row)}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <MoreVertIcon />
          </IconButton>
        </React.Fragment>
      )
    }
  ];

  const searchedRows = useMemo(() => {
    return filteredRows.filter(row => row.client_name.toLowerCase().includes(searchFilter.toLowerCase()));
  }, [filteredRows, searchFilter]);

  return (
    <Box component="section" mb={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h5">Interest List</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ mr: 1 }}>
            <Select
              size="small"
              value={selectedCategory}
              onChange={(event: SelectChangeEvent<keyof FilterOptions>) => handleCategoryChange(event)}
              placeholder="Filter by Category"

              renderValue={(selected) => selected}
              MenuProps={{
                PaperProps: {
                  style: {
                    width: 250,
                    maxHeight: 500,
                  },
                },
              }}
            >
              {Object.entries(filterOptions(approvedSigner)).map(([category, options]) => (
                <MenuItem key={category} value={category}>{category}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ mr: 1 }}>
            <Select
              multiple
              size="small"
              sx={{ minWidth: 200 }}
              value={selectedFilterOptions}
              displayEmpty
              onChange={(event: SelectChangeEvent<number[]>) => handleOptionChange(event)}
              placeholder="Filter by Status"
              renderValue={(selected) => {
                if (selected.length === 1) {
                  return <Typography sx={{ color: grey[500] }}>{selected.length} Status Selected</Typography>
                }
                return selected.length > 0 ?
                  <Typography sx={{ color: grey[500] }}>{selected.length} Statuses Selected</Typography> : <Typography sx={{ color: grey[500] }}>Filter By Statuses</Typography>
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    width: 250,
                    maxHeight: 500,
                  },
                },
              }}
            >
              {filterOptions(approvedSigner)[selectedCategory].map((option) => (
                <FormControlLabel
                  key={option.value}
                  sx={{ m: 1 }}
                  control={
                    <Checkbox sx={{ py: 1, mr: 1 }} checked={selectedFilterOptions.includes(option.value)} onChange={(event: ChangeEvent, checked) => handleFilterChange(checked, option)} name={option.value.toString()} />
                  }
                  label={option.label}
                />
              ))}
            </Select>
          </FormControl>
          <TextField
            value={searchFilter}
            onChange={(event) => setSearchFilter(event.target.value)}
            placeholder="Search Interest List"
            size="small"
            helperText=""
            InputProps={{
              startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
            }} />
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <DataSourceTable columnDef={distributionsColumnDef} dataSource={searchedRows} emptyMessage="No clients" showTotals rowState={rowState} onRowStateChange={handleRowStateChange} />
        <ConfirmationDialog
          open={dialogs.isConfirmationDialogOpen}
          handleClose={handleCloseConfirmationDialog}
          message={confirmationDialogConfig?.message || ''}
          titleNode={confirmationDialogConfig?.title || ''}
          yesButtonLabel="Confirm"
          noButtonLabel="Cancel"
        />
      </TableContainer>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseEllipsis}
      >
        <MenuItem
          onClick={() => {
            handleOpenConfirmationDialog(selectedRow, 'Delete', `You are about to delete ${selectedRow ? (selectedRow.account_name || selectedRow.client_name) : 'the selected account'} from the Interest List.`);
            handleCloseEllipsis();
          }}
          disabled={
            (selectedRow?.sub_doc_submission_health !== SubDocStatus.InProgress) && selectedRow?.sub_doc_submission_health !== null
          }
        >
          Delete
        </MenuItem>
        {selectedRow?.sub_doc_submission_health === SubDocStatus.AwaitingSignature && (
          <>
            <MenuItem onClick={() => {
              handleResend();
              handleCloseEllipsis();
            }}>
              Resend
            </MenuItem>
            <MenuItem onClick={() => {
              handleOpenConfirmationDialog(selectedRow, 'Void', `You are about to void the DocuSign envelope which will send all signers a 'Void Confirmation' for ${selectedRow ? selectedRow.account_name : 'the selected account'}.`);
              handleCloseEllipsis();
            }}>
              Void
            </MenuItem>
          </>
        )}
        {selectedRow?.sub_doc_submission_health === SubDocStatus.Signed && (
          <>
            <MenuItem disabled>
              Resend
            </MenuItem>
            <MenuItem disabled>
              Void
            </MenuItem>
          </>
        )}
      </Menu>
      {selectedRow && isProspectRow(selectedRow) && (
        <InvestorDialog
          mode="edit"
          open={dialogs.isAddInvestorDialogOpen}
          handleClose={handleCloseAddInvestorDialog}
          setRefreshDataTrue={setRefreshDataTrue}
          investedList={investedList}
          interestedList={interestedList}
          preSelectedClient={clientsMap[selectedRow.client_id!] || undefined}
          existingInvestorData={selectedRow}
        />
      )}
      <Dialog key={envelopeStatusData.length} open={dialogs.isEnvelopeStatusDialogOpen} onClose={handleCloseEnvelopeStatusDialog}>
        <DialogTitle>Awaiting Signature Status</DialogTitle>
        <DialogContent>
          <Typography>
            Account Name: {envelopeStatusData[0]?.account?.accountName ?? 'N/A'}
          </Typography>
        </DialogContent>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sent On</TableCell>
                <TableCell>Recipients</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {envelopeStatusData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3}>No Data Available</TableCell>
                </TableRow>
              ) : (
                envelopeStatusData.flatMap((envelope) => {
                  return envelope.recipients.map((recipient, index) => {
                    const sentDateTime = recipient.sentDateTime === null ? 'Not Sent' :
                      moment(recipient.sentDateTime).format('MM/DD/YYYY hh:mm:ss');
                    const statusDetails = recipient.status === RecipientStatus.Signed || recipient.status === RecipientStatus.Completed
                      ? `Signed, ${moment(recipient.signedDateTime).format('MM/DD/YYYY hh:mm:ss')}`
                      : 'Awaiting Signature';
                    return (
                      <TableRow key={`${envelope.envelopeId}-${index}`}>
                        <TableCell>{sentDateTime}</TableCell>
                        <TableCell>{recipient.signerName}</TableCell>
                        <TableCell>{statusDetails}</TableCell>
                      </TableRow>
                    );
                  });
                })
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEnvelopeStatusDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}


const FundStatusChip: FC<{ status: FundStatus }> = ({ status }) => {
  const statusMap = {
    [FundStatus.NotSubmitted]: { color: "error" as "error", label: "Not Submitted" },
    [FundStatus.Submitted]: { color: "info" as "info", label: "Submitted" },
    [FundStatus.Pending]: { color: "success" as "success", label: "Pending" },
    [FundStatus.Approved]: { color: "success" as "success", label: "Accepted" },
    [FundStatus.Rejected]: { color: "warning" as "warning", label: "Rejected" },
    [FundStatus.Rolled]: { color: "info" as "info", label: "Rolled" },
    [FundStatus.Withdrawn]: { color: "error" as "error", label: "Withdrawn" },
  };

  const { color, label } = statusMap[status];

  return <Chip color={color} clickable={false} size="small" label={label} variant="outlined" />;
};

const SubDocStatusChip: FC<{ status: SubDocStatus }> = ({ status }) => {
  const statusMap = {
    [SubDocStatus.NotStarted]: (
      <Box color="info" sx={{ p: 1, pt: 0, pb: 0, backgroundColor: yellow[700], color: grey[800], borderRadius: '4px', fontWeight: 500, fontSize: '.875rem' }}>
        Start Now
      </Box>
    ),
    [SubDocStatus.InProgress]: (
      <Box color="info" sx={{ p: 1, pt: 0, pb: 0, backgroundColor: yellow[700], color: grey[800], borderRadius: '4px', fontWeight: 500, fontSize: '.875rem' }}>
        Resume
      </Box>
    ),
    [SubDocStatus.AwaitingSignature]: (
      <Chip color="attention" clickable={false} size="small" label="Awaiting Signature" variant="filled" />
    ),
    [SubDocStatus.Signed]: (
      <Chip color="info" clickable={false} size="small" label="Signed" variant="outlined" />
    ),
    [SubDocStatus.AwaitingApproval]: (
      <Chip color="info" clickable={false} size="small" label="Submitted" variant="outlined" />
    ),
    [SubDocStatus.Approved]: (
      <Chip color="complete" clickable={false} size="small" label="Completed" variant="filled" />
    ),
  };
  return statusMap[status] || null;
};

const AccreditationStatusChip: FC<{ status: AccreditationStatus | 'StartNow' }> = ({ status }) => {
  const statusMap = {
    [AccreditationStatus.NotStarted]: (
      <Chip color="attention" clickable={false} size="small" label="Not Started" variant="filled" />
    ),
    [AccreditationStatus.Completed]: (
      <Chip color="complete" clickable={false} size="small" label="Completed" variant="filled" />
    ),
    StartNow: (
      <Box color="info"
        sx={{ p: 1, pt: 0, pb: 0, backgroundColor: yellow[700], color: grey[800], borderRadius: '4px', fontWeight: 500, fontSize: '.875rem' }}>
        Start Now
      </Box>
    ),
  };
  return statusMap[status] || null;
};

const MoniesStatusChip: FC<{ status: MoniesStatus }> = ({ status }) => {
  const statusMap = {
    [MoniesStatus.NotReceived]: { color: "info" as "info", label: "Pending" },
    [MoniesStatus.Received]: { color: "complete" as "complete", label: "Received" },
  };

  const { color, label } = statusMap[status];
  return <Chip color={color} clickable={false} size="small" label={label} variant="outlined" />;
};

const FundStatusChips = {
  NotSubmitted: <FundStatusChip status={FundStatus.NotSubmitted} />,
  Submitted: <FundStatusChip status={FundStatus.Submitted} />,
  Pending: <FundStatusChip status={FundStatus.Pending} />,
  Approved: <FundStatusChip status={FundStatus.Approved} />,
  Rejected: <FundStatusChip status={FundStatus.Rejected} />,
  Rolled: <FundStatusChip status={FundStatus.Rolled} />,
  Withdrawn: <FundStatusChip status={FundStatus.Withdrawn} />,
};

// =============================================//

const SubDocStatusChips = {
  NotStarted: <SubDocStatusChip status={SubDocStatus.NotStarted} />,
  InProgress: <SubDocStatusChip status={SubDocStatus.InProgress} />,
  AwaitingSignature: <SubDocStatusChip status={SubDocStatus.AwaitingSignature} />,
  Signed: <SubDocStatusChip status={SubDocStatus.Signed} />,
  AwaitingApproval: <SubDocStatusChip status={SubDocStatus.AwaitingApproval} />,
  Approved: <SubDocStatusChip status={SubDocStatus.Approved} />,
};

const AccreditationStatusChips = {
  NotStarted: <AccreditationStatusChip status={AccreditationStatus.NotStarted} />,
  Completed: <AccreditationStatusChip status={AccreditationStatus.Completed} />,
  StartNow: <AccreditationStatusChip status="StartNow" />,
};

const MoniesStatusChips = {
  NotReceived: <MoniesStatusChip status={MoniesStatus.NotReceived} />,
  Received: <MoniesStatusChip status={MoniesStatus.Received} />,
};

const filterOptions: (approvedSigner: boolean) => FilterOptions = (approvedSigner: boolean) => ({
  "Sub Docs Status": [
    { value: SubDocStatus.NotStarted, label: SubDocStatusChips.NotStarted },
    { value: SubDocStatus.InProgress, label: SubDocStatusChips.InProgress },
    { value: SubDocStatus.AwaitingSignature, label: SubDocStatusChips.AwaitingSignature },
    { value: SubDocStatus.Signed, label: SubDocStatusChips.Signed },
    { value: SubDocStatus.AwaitingApproval, label: SubDocStatusChips.AwaitingApproval },
    { value: SubDocStatus.Approved, label: SubDocStatusChips.Approved },

  ],
  "Accreditation Status": [
    { value: AccreditationStatus.NotStarted, label: approvedSigner ? AccreditationStatusChips.StartNow : AccreditationStatusChips.NotStarted },
    { value: AccreditationStatus.Completed, label: AccreditationStatusChips.Completed },
  ],
  "Monies Status": [
    { value: MoniesStatus.NotReceived, label: MoniesStatusChips.NotReceived },
    { value: MoniesStatus.Received, label: MoniesStatusChips.Received },
  ],
  "Fund Status": [
    { value: FundStatus.NotSubmitted, label: FundStatusChips.NotSubmitted },
    { value: FundStatus.Submitted, label: FundStatusChips.Submitted },
    { value: FundStatus.Pending, label: FundStatusChips.Pending },
    { value: FundStatus.Approved, label: FundStatusChips.Approved },
    { value: FundStatus.Rejected, label: FundStatusChips.Rejected },
    { value: FundStatus.Rolled, label: FundStatusChips.Rolled },
    { value: FundStatus.Withdrawn, label: FundStatusChips.Withdrawn },
  ],
});